* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

/* nav section */
#mynav img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    cursor: pointer;
}

#navbarSS {
    justify-content: right;
    font-size: 1.7rem;
}

#navbarSS a {
    text-transform: capitalize;
    color: #121121;
    text-decoration: none;
    margin: 0px 30px;
    font-weight: 650;
    font-size: 1.25rem;
}

#navbarSS a:hover {
    color: #7ed854;
}

#mynav {
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

#mynav.transparent {
    background-color: #ffffff;
}

#mynav.transparent a {
    color: #121121;
}

.navbar-toggler {
    box-shadow: none !important;
}

#navbarDropdown {
    text-transform: capitalize;
    color: #121121;
    font-weight: 650;
    font-size: 1.25rem;
}

.dropdown-item {
    width: 100% !important;
    margin: 0 !important;
    padding: auto auto !important;
}

.dropdown-item:hover {
   background-color: white !important;
}

footer svg {
    cursor: pointer;
    margin: 0px 20px;
    color: #121121;
}

footer svg:hover {
    color: #7ed854;
}

footer p {
    font-size: 14px;
    font-weight: 600;
    color: #121121;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
}

.modal-content {
  margin: auto;
  display: block;
  max-width: 80%;
  max-height: 80%;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.view h6 {
    cursor: pointer;
    color: #fff;
    background-color: #121121;
    padding: 10px 20px;
    margin: 20px 0px;
}

.view a {
    text-decoration: none !important;
}

.view h6:hover {
    color: #7ed854;
}

.search {
    background-color: #88d45f;
    max-height: 100px;
    overflow: hidden;
    overflow-y: auto;
}

.search a {
    text-decoration: none;
    color: initial;
    width: 100%;
    display: block;
}

.search a:hover {
    background-color: black;
    color: white;
}





.main-box.no-header {
    padding-top: 20px;
}
.main-box {
    background: #FFFFFF;
    -webkit-box-shadow: 1px 1px 2px 0 #CCCCCC;
    -moz-box-shadow: 1px 1px 2px 0 #CCCCCC;
    -o-box-shadow: 1px 1px 2px 0 #CCCCCC;
    -ms-box-shadow: 1px 1px 2px 0 #CCCCCC;
    box-shadow: 1px 1px 2px 0 #CCCCCC;
    margin-bottom: 16px;
    -webikt-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.table a.table-link.danger {
    color: #e74c3c;
}
.label {
    border-radius: 3px;
    font-size: 0.875em;
    font-weight: 600;
}
.user-list tbody td .user-subhead {
    font-size: 0.875em;
    font-style: italic;
}
.user-list tbody td .user-link {
    display: block;
    font-size: 1.25em;
    padding-top: 3px;
    margin-left: 60px;
}
a {
    color: #3498db;
    outline: none!important;
}
.user-list tbody td>img {
    position: relative;
    max-width: 50px;
    float: left;
    margin-right: 15px;
}

.table thead tr th {
    text-transform: uppercase;
    font-size: 0.875em;
}
.table thead tr th {
    border-bottom: 2px solid #e7ebee;
}
.table tbody tr td:first-child {
    font-size: 1.125em;
    font-weight: 300;
}
.table tbody tr td {
    font-size: 0.875em;
    vertical-align: middle;
    border-top: 1px solid #e7ebee;
    padding: 12px 8px;
}
a:hover{
text-decoration:none;
}

@media(max-width:425px) {
    #mynav img {
        width: 80px;
        height: 50px;
    }
}

@media (max-width: 768px) {
    #navbarSS {
        background-color: #121121;
    }

    #mynav.transparent a {
        color: #7ed854;
    }

    #mynav a {
        color: #828687;
        font-size: 14px;
        padding: 3px;
        margin: 0;
    }

    #mynav a :active {
        color: red;
    }
}