.list {
    background-color: whitesmoke;
    margin: 10px 0;
    padding: 10px;
}

#playy{
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#play:hover {
    background-color: #95a78b;
    border-radius: 8px;
}

.list:hover {
    background-color: #88d45f;
}

#play .icon {
    color: black;
    cursor: pointer;
}

#play .icon:hover {
    color: white;
}

#latestH5 {
    color: #121121;
    margin-top: 10px;
    text-align: center;
    font-weight: 700;
}

.list-btw {
    display: flex;
    justify-content: space-between;
    text-transform: lowercase;
}