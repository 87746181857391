.lectureInput {
    margin-right: 6px;
    border-radius: 3px;
    background-color: white;
}

.lectureTopBar {
    background-color: #008001;
    padding: 5px;
    border-radius: 5px;
}

.lectureInput input,
.lectureInput select {
    width: 100%;
    height: 50px;
    font-size: larger;
    outline: none;
    border: none;
}
