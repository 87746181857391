.card-img p {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: rgba(76, 76, 69, 0.7);
    padding: 5px;
    text-transform: capitalize;
    color: white;
    font-weight: 700;
}

.card img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
}

.card {
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 30px;
    border: 0px;
}

.card-body {
    padding: 15px 0px;
}

.card h4 {
    color: green;
    font-size: 20px;
}

.card-btw {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
}

.card a {
    text-decoration: none;
    color: initial;
}

.card a:hover {
    text-decoration: none;
    color: initial;
}

.zoom:hover{
    transform: scale(1.01);
    /* border: 1px solid black; */
}