.login-form {
  background-color: whitesmoke;
  width: 50%;
  margin: 0 auto;
  padding: 50px;
}

.login-form button {
  margin: 0 auto;
  display: flex;
  justify-self: center;
}
.login-form button:hover {
  background-color: silver;
  color: black;
}
.login-form button:focus,
.login-form input:focus {
  box-shadow: none;
}
.login-form input,
.login-form select:active,
.login-form select {
  border: none;
}

.login-form select {
  width: fit-content !important;
}

#logout {
  background-color: black;
  color: white;
  float: right;
  border: none;
  margin-top: 30px;
}

#logout:hover {
  background-color: rgb(70, 68, 68);
  transform: scale(1.05);
}

.login-form a {
  color: black;
}
.error {
  color: red;
}

@media (max-width: 768px) {
  .login-form {
    padding: 10px;
  }
}

@media (max-width: 425px) {
  .login-form {
    width: 100%;
    padding: 50px 0px;
  }
}
